import { cn } from "@/lib/utils";
import React from "react";

// Define the Props interface for the component
export interface IconListItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  icon: string;
  text: string;
}

const IconListItem = ({
  className,
  icon,
  text,
  ...props
}: IconListItemProps) => {
  const IconComponent = icon
    ? React.lazy(() => import(`./../icons/components/${icon}.tsx`))
    : null;

  return (
    <div
      className={cn(
        className,
        "flex h-10 w-full flex-row items-end justify-between gap-8 border-b-2 border-muted text-right",
      )}
      {...props}
    >
      <div className=" flex items-center border-r-2 border-muted px-4 pb-2">
        {IconComponent && (
          <React.Suspense fallback={" "}>
            <IconComponent />
          </React.Suspense>
        )}
      </div>
      <div className="flex items-center justify-center pb-2 text-Header6">
        {text}
      </div>
    </div>
  );
};

IconListItem.displayName = "IconListItem";

export default IconListItem;
